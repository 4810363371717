

import Loading from './src/Loading.vue'
import { App } from 'vue'


Loading.install = (app: App) => {
    app.component(Loading.name, Loading)
}

export default Loading
