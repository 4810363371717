<template>
  <main v-if="data">
    <div class="banner">
      <div class="swiper-slide">
        <router-link
          :to="item.slideMLink"
          v-for="(item, index) in data.listimg"
          :key="index"
          :style="{ backgroundImage: `url(${item.slideImg})` }"
          :title="item.slideAlt"
          class="swiper-content"
        >
        </router-link>
      </div>

    </div>

    <div class="main-title">
      Contact Weiyel
    </div>

    <div class="position-info">

      <p>Whatsapp：+8613275979861</p>
      <p>
        <a style="color:#666;text-decoration: underline" itemprop="email" href="mailto:info@weiyel.com" title="E-mail">Email : info@weiyel.com</a>

      </p>
<!--      <p>Address：No. 218, Tangli Road, Chaoyang District, Beijing, China</p>-->
      <p>Weiyel Inc</p>
      <div class="contract-wapper">
<!--        <a-->
<!--          href="https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1"-->
<!--          class="inline"-->
<!--        >-->
          <a @click="chat()">
          <img src="@/assets/img/about/icon98.png" alt="" />
          Online customer service
        </a>
      </div>
    </div>
    <div class="block" style="height: 8.5rem;">
      <div class="block-title">
        Company Profile<br />
        <p>Leading RM Producer
        </p>
      </div>
      <div class="video-wapper">

        <video
          id="player-container-id"
          src="https://1256414354.vod2.myqcloud.com/c8e6626avodtransgzp1256414354/ba91957b243791580683347934/v.f40.mp4"
          style="width: 100%; height: 100%"
          playsinline
          webkit-playinline
          x5-playinline
          controls
          autoplay
          poster="https://weiyejiliang.oss-accelerate.aliyuncs.com/waimao/about_video_cover.jpg"
        ></video>
      </div>
    </div>
    <div class="block">
      <div class="block-title border-bottom-1px">
        Account Information<br />
      </div>
      <div class="account-info">
        <ul>
          <li>
            <p style="padding: 0">Account Name: HeNan YuNan Testing Center Co., Ltd</p>
            <p>Account No: 16740814040001991</p>
            <p>Bank: Agricultural Bank of China HeNan Branch XinYang Branch</p>
            <p>Bank Address: 272 DongFangHong Avenue XinYang City HeNan China</p>
            <p>SWIFT Code: ABOCCNBJ160</p>
          </li>
          <li>
            <p style="padding: 0">Account Name: Aoke Biology Research (HK)Co.,Ltd</p>
            <p>Account No:817-819139-838</p>
            <p>Bank: HSBC Hong Kong </p>
            <p>Bank Address: No.1 Queen's Road Central Hong Kong</p>
            <p>SWIFT Code: HSBCHKHHHKH</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="tip-block">

      <div class="tip">
        <span>Leading RM Producer</span>
<!--        <p>国家标准物质研制单位 标准物质一站式采购平台</p>-->
        <p>
          100000+
          Worldwide Customers
        </p>
        <p>
          50+
          Serving Global Countries
        </p>
        <p>
          15000+
          Reference material products
        </p>
        <p>
          24000+

          Reference Material R&D Base

        </p>

      </div>

    </div>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import Swiper from "swiper";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";

import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "About",
  components: {
    Loading,
  },
  setup() {
    const data = ref(null);

    // nextTick(() => {
    //   asyncLoadJs(
    //     "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
    //     "about"
    //   ).then(() => {
    //     asyncLoadJs(
    //       "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
    //       "about"
    //     ).then((res) => {
    //       vTCPlayer("player-container-id", "387702295832720318",true);
    //       // vTCPlayer("player-bncc-id", "387702293224742253",true);
    //       vTCPlayer("playerContentVideo", "5285890792153886246",true);
    //     });
    //   });
    // })

    const userInfo = inject("userInfo") as any;

    axios
      .get("/M/Home/About")
      .then((res) => {
        if (res.data.success) {
          const kefulist = [];
          const length = Math.ceil(res.data.obj.kefulist.length / 6);
          for (let i = 0; i < length; i++) {
            kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
          }
          data.value = { ...res.data.obj, kefulist };

        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });

    watch(data, () => {
      nextTick(() => {
        const swiper = new Swiper(".swiper-container", {
          initialSlide: 0,
          autoplay: true,
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          pagination: {
            el: ".swiper-pagination",
          },
        });
      });
    });



    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };
      function chat() {
          window.open(
              "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
              "_blank",
              "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
          );
          return false;
      }
      // function open53() {
      //     var _53api = window.$53.createApi();    //$53这个对象是从53挂码获取的
      //     _53api.push('cmd', 'kfclient');
      //     _53api.push('type', 'new');    //popup是弹窗，new是新窗口。浮动窗口就是页面右下角弹出的小窗口，新窗口是一个相对大的浮层窗口，可以自己测试看下
      //     //_53api.push('worker_id', '1006');    //这个参数用于指定接待客服。正常不用设置，会按照53后台设置的接待规则分配客服。worker_id是客服的工号，用于指定接待客服，如果不设置，会按照后台设置的接待规则分配客服。账号对应的工号可以到工号管理中查看。工号管理登录地址 http://open.53kf.com/?go_url=https://saasjh.53kf.com/2mastersaas
      //     _53api.query();
      // }
    return {
      data,
      loadOption,
      chat,
    };
  },
});
</script>


<style lang="scss" scoped>
.banner {
  margin-bottom: 0.533rem;
}
.banner .swiper-content {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.banner .swiper-content img {
  /*width: 100%;*/
  height: 4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.banner .swiper-container {
  --swiper-theme-color: #fff;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 1.067rem;
  /* 设置按钮大小 */
  --swiper-pagination-color: #df0024;
  /* 两种都可以 */
}

.banner .my-bullet {
  display: inline-block;
  width: 0.347rem;
  height: 0.067rem;
  background-color: #ffffff;
  margin: 0 0.067rem;
  border-radius: 0;
  opacity: 1;
}

.banner .my-bullet-active {
  background-color: #df0024;
}

.free-tel-model {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: none;
}

.free-tel-wapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.267rem;
  z-index: 10001;
  display: none;
}
.free-tel-title {
  padding: 0.533rem 0.533rem 0.267rem;
  margin-bottom: -0.667rem;
  text-align: center;
  font-size: 0.467rem;
  font-weight: 400;
  color: #333;
}

#callMeTel {
  margin-left: 0.533rem;
  width: 87%;
  height: 1.067rem;
  line-height: 1.067rem;
  border: 0.027rem solid #c10728;
  padding-left: 0.267rem;
  outline: none;
}

.free-tel-btn {
  border-top: 0.027rem solid #eee;

  font-size: 0;
  margin-top: 0.533rem;
}
.free-tel-btn span {
  display: inline-block;
  height: 1.173rem;
  line-height: 1.173rem;
  color: #007aff;
  font-size: 0.453rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  width: 50%;
  text-align: center;
  zoom: 1;
  box-sizing: border-box;
}

.free-tel-btn .free-tel-cancel {
  color: #999;
}
.free-tel-confirm {
  border-left: 0.027rem solid #eee;
}

.free-tel-tip {
  padding: 0.533rem 0.533rem 0.267rem;
  font-size: 0.28rem;
}

main {
  padding-bottom: 1.333rem;
}

.main-title {
  font-size: 0.46rem;
  color: #444444;
  font-weight: bold;
  width: 9.467rem;
  margin: 0 auto;
  border-bottom: 0.027rem solid #eee;
  padding-bottom: 0.1rem;
}

.main-title span {
  text-transform: uppercase;
  font-size: 10px;
  color: #666;
  font-weight: normal;
  position: relative;
  top: -5px;
}

.position-info {
  font-size: 0.4rem;
  color: #666;
  line-height: 0.64rem;
  padding: 0.4rem 0.267rem;
  width: 9.467rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 0;
}
.position-info p {
  margin: 0;
}
.contract-wapper {
  margin-top: 0.533rem;
  display: flex;
  justify-content: space-between;
}
.contract-wapper a {
  display: inline-block;
  /*width: 3.733rem;*/
  height: 1.067rem;
  line-height: 1.067rem;
  text-align: left;
  font-size: 0.427rem;
  border: 0.027rem solid #e5e5e5;
  border-radius: 0.133rem;
  padding: 0 0.25rem;
  box-sizing: border-box;
  color: #666;
}





.contract-wapper img {
  width: 0.467rem;
  height: 0.48rem;
  vertical-align: middle;
  margin-right: 0.333rem;
}

.block {
  border-top: 0.16rem solid #eee;
  padding: 0.2rem 0.267rem;
  border-bottom: 0.027rem solid #eee;
}

.block-title {
  font-size: 0.46rem;
  color: #444444;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 0.027rem solid #eee;
}

.block-title p {
  font-size: 14px;
  color: #666;
  font-weight: normal;
  /*text-transform: uppercase;*/
  margin: 0.1rem 0 0;

}

.border-bottom-1px {
  border-bottom: 0.027rem solid #eee;
}

.custom-service {
  width: 9.467rem;
  margin: 0 auto;
  padding: 0.533rem 0;
}
.custom-service-ul {
  padding-bottom: 2.133rem;
  overflow: hidden;
}
.custom-service-ul li {
  float: left;
  width: 49%;
  padding-left: 1.52rem;
  margin: 0;
  margin-bottom: 5px;
  box-sizing: border-box;
  position: relative;
  font-size: 0.293rem;
  line-height: 0.48rem;
  color: #666;
}

.custom-service-ul li img {
  width: 1.253rem;
  height: 1.253rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-service-ul li span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.account-info li {
  border-bottom: 0.027rem solid #eee;
  padding: 0.533rem 0;
}

.account-info p {
  font-size: 0.4rem;
  line-height: 0.587rem;
  color: #444444;
  margin: 0 auto;
}

.create-img {
  width: 100%;
  display: block;
}

.tip {
  width: 100%;
  height: 8rem;
  background-color: #df0024;
  text-align: center;
  font-size: 0.533rem;
  color: #fff;
  padding-top: 1.6rem;
  box-sizing: border-box;
  font-weight: bold;
}

.tip p {
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.4rem;
  font-weight: normal;
  //text-align: left;
  //padding-left: 1.5rem;
  text-align: center;
}

.tip-container p {
  font-size: 0.4rem;
  color: #444444;
  line-height: 0.587rem;
  margin-top: 0.267rem;
  text-indent: 2em;
}

.tip-container a {
  color: #df0024;
}

.development-history {
  border-top: 0.16rem solid #eee;

  padding: 0 0.267rem;
  padding-top: 0.667rem;
  padding-bottom: 1.333rem;
}
.dev-title {
  text-align: center;
  font-size: 0.46rem;
  font-weight: bold;
  color: #444444;
}

.dev-desc {
  font-size: 0.4rem;
  color: #444444;
  margin-top: 0.4rem;
  font-weight: bold;
  text-align: center;
}

.dev-list {
  font-weight: normal;
  font-size: 0.427rem;
  color: #444444;
  border-bottom: 0.027rem solid #eee;
}

.dev-list p {
  margin: 1em 0;
}

.video-wapper {
  width: 9.467rem;
  height: 5.333rem;
  margin: 0.4rem auto;
}

  .video_title{
    font-size: 0.36rem;
    color: #444444;
    margin: 0 auto;
    padding-left: 0;
  }
</style>
