
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import Swiper from "swiper";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";

import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "About",
  components: {
    Loading,
  },
  setup() {
    const data = ref(null);

    // nextTick(() => {
    //   asyncLoadJs(
    //     "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
    //     "about"
    //   ).then(() => {
    //     asyncLoadJs(
    //       "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
    //       "about"
    //     ).then((res) => {
    //       vTCPlayer("player-container-id", "387702295832720318",true);
    //       // vTCPlayer("player-bncc-id", "387702293224742253",true);
    //       vTCPlayer("playerContentVideo", "5285890792153886246",true);
    //     });
    //   });
    // })

    const userInfo = inject("userInfo") as any;

    axios
      .get("/M/Home/About")
      .then((res) => {
        if (res.data.success) {
          const kefulist = [];
          const length = Math.ceil(res.data.obj.kefulist.length / 6);
          for (let i = 0; i < length; i++) {
            kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
          }
          data.value = { ...res.data.obj, kefulist };

        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });

    watch(data, () => {
      nextTick(() => {
        const swiper = new Swiper(".swiper-container", {
          initialSlide: 0,
          autoplay: true,
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          pagination: {
            el: ".swiper-pagination",
          },
        });
      });
    });



    const loadOption = {
      text: "Loading...",
      color: "#df0024",
      textColor: "#df0024",
    };
      function chat() {
          window.open(
              "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
              "_blank",
              "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
          );
          return false;
      }
      // function open53() {
      //     var _53api = window.$53.createApi();    //$53这个对象是从53挂码获取的
      //     _53api.push('cmd', 'kfclient');
      //     _53api.push('type', 'new');    //popup是弹窗，new是新窗口。浮动窗口就是页面右下角弹出的小窗口，新窗口是一个相对大的浮层窗口，可以自己测试看下
      //     //_53api.push('worker_id', '1006');    //这个参数用于指定接待客服。正常不用设置，会按照53后台设置的接待规则分配客服。worker_id是客服的工号，用于指定接待客服，如果不设置，会按照后台设置的接待规则分配客服。账号对应的工号可以到工号管理中查看。工号管理登录地址 http://open.53kf.com/?go_url=https://saasjh.53kf.com/2mastersaas
      //     _53api.query();
      // }
    return {
      data,
      loadOption,
      chat,
    };
  },
});
